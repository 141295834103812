<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="hubDetailsModal"
      :title="$t('Hub Details')"
      :buttons-hidden="true">
      <div class="px-4">
        <div class="border-solid border-grey-light border p-4 rounded mb-6 mt-2">
          <ul class="font-medium" style="list-style-type: disc; list-style-position: inside;">
            <li>{{$t('The service level of the drop-off shipments ranges from 1:2 business days')}}</li>
            <li class="mt-4">{{$t('Working days are from')}} <span class="text-primary">{{$t('Saturday')}}</span> {{$t('to')}} <span class="text-primary">{{$t('Thursday')}}</span>.</li>
            <li class="mt-4">{{$t('Working Hours are from')}} <span class="text-primary">{{$t('1 PM')}}</span> {{$t('to')}} <span class="text-primary">{{$t('6 PM')}}</span>.</li>
          </ul>
        </div>
        <div class="border-solid border-grey-light border p-4 rounded text-center mb-4">
          <div class="w-16 h-16 rounded-full flex items-center justify-center m-auto mb-4" style="background-color: #F2F2F2;">
            <feather-icon icon="MapPinIcon" svgClasses="text-primary"/>
          </div>
          <p class="mb-2 font-medium text-lg">{{ $t(hubDetails.name + ' ' + 'HUB') }}</p>
          <a class="text-sm" :href="`https://www.google.com/maps?q=${hubDetails.latitude},${hubDetails.longitude}&z=15`" target="_blank">{{$t('Click to view location')}}</a>
          <p class="mt-2 font-light mb-4">{{ hubDetails.address }}</p>
        </div>
        <p class="text-black text-sm">{{$t('If this is not the nearest hub to your location, please contact our client support team for assistance in providing the address of a more convenient hub.')}}</p>
    </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['hubDetailsModal', 'hubDetails'],
  methods: {
    closeModal () {
      this.$emit('hubDetailsModal', false)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>